import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BannerOne from "../../../../assets/Home/Banner/alex-duffy-a-E__y8y5Wo-unsplash.jpg";
import BannerTwo from "../../../../assets/Home/Banner/artyom-korshunov-NWByxwVN-J0-unsplash.jpg";
import BannerThree from "../../../../assets/Home/Banner/dominik-vanyi-F1B0-iuAxO4-unsplash.jpg";
import BannerFour from "../../../../assets/Home/Banner/maksym-kaharlytskyi-kDVaFjoQf4M-unsplash.jpg";
import BannerFive from "../../../../assets/Home/Banner/venti-views-FPKnAO-CF6M-unsplash.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const { t, i18n } = useTranslation(["Common"]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [BannerOne, BannerTwo, BannerThree, BannerFour, BannerFive];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [images]);

  const bannerStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "520px",
    transition: "background-image 4s ease-in-out",
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    rtl: false,
  };

  return (
    <div
      className="relative banner transition-background-image "
      style={bannerStyle}
    >
      <div className="absolute inset-0 bg-black h-[520px] opacity-70"></div>
      <div className="absolute inset-0 flex items-center justify-center text-4xl font-bold text-white">
        <div className=" w-4/5 mt-16 ">
          <Slider {...settings}>
            {/* ---- */}
            <div className="  text-center  ">
              <div
                loading="lazy"
                className=" text-center flex flex-col items-center "
              >
                <p className="  font-medium text-lg lg:text-xl">
                  {t("Common.name")}
                </p>
                <p className="gold  text-xl lg:text-2xl py-2">
                  {" "}
                  {t("Common.bannerSlide")}
                </p>
                <p className="text-xs  text-gray-200  lg:text-lg line-clamp-3 lg:line-clamp-none font-normal my-2">
                  {t("Common.bannerSlide")} 🌐✨ #TradeWithAsfawTesfaye
                </p>
                <Link
                  to="/contact"
                  className=" justify-center text-center bg-gradient-to-r from-orange-800 to-orange-600 w-48 text-lg px-3 font-medium py-2 my-3 rounded-md"
                >
                  {t("Common.ContactUs")}
                </Link>
              </div>
            </div>
            {/* --- */}
            <div className="  text-center  ">
              <div
                loading="lazy"
                className=" text-center flex flex-col items-center "
              >
                <p className="  font-medium text-xl lg:text-3xl">
                  {t("Home.Banner.First.topTitleOne")}
                </p>
                <p className="gold  text-xl lg:text-3xl py-2">
                  {" "}
                  {t("Home.Banner.First.topTitleTwo")}
                </p>
                <p className="text-xs  text-gray-200  lg:text-lg line-clamp-3 lg:line-clamp-none font-normal my-2">
                  {t("Home.Banner.First.Discription")}
                </p>
                <Link
                  to="/contact"
                  className=" justify-center text-center bg-gradient-to-r from-orange-800 to-orange-600 w-48 text-lg px-3 font-medium py-2 my-3 rounded-md"
                >
                  {t("Common.ContactUs")}
                </Link>
              </div>
            </div>
            <div>
              <div
                loading="lazy"
                className="  flex flex-col  ps-6 text-center "
              >
                <div className=" text-center">
                  <p className="  font-medium text-xl lg:text-3xl">
                    {t("Home.Banner.Second.topTitleOne")}
                  </p>
                  <p className="gold  text-xl lg:text-3xl py-2 ">
                    {t("Home.Banner.Second.topTitleTwo")}
                  </p>
                  <p className="text-xs  text-gray-200  lg:text-lg line-clamp-3 lg:line-clamp-none font-normal my-2">
                    {t("Home.Banner.Second.Discription")}
                  </p>
                  <Link
                    to="/contact"
                    className=" justify-center text-center bg-gradient-to-r from-orange-800 to-orange-600 w-48 text-lg px-3 font-medium py-2 my-3 rounded-md"
                  >
                    {t("Common.ContactUs")}
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Banner;
