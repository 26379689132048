import React from 'react';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const WhatsAppChatButton = () => {
  const phoneNumber = '+251911248620'; // Replace with your phone number

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-chat-button rounded-full p-2 duration-200 hover:scale-110  "
    >
      <WhatsAppIcon style={{width:30,height:30}}/>
    </a>

  );
};

export default WhatsAppChatButton;