import React from "react";
import { motion } from "framer-motion";
import DirectionsBoatOutlinedIcon from "@mui/icons-material/DirectionsBoatOutlined";
import { GiMining } from "react-icons/gi";
import FlightIcon from "@mui/icons-material/Flight";
import ServiceList from "./ServiceList/ServiceList";
import { useTranslation } from "react-i18next";

const variants = {
  initial: {
    y: 100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const OurServices = () => {
 const { t } = useTranslation(["Common"]);
  return (
    <motion.div
      variants={variants}
      initial="initial"
      whileInView="animate"
      className=" bg-white pt-10"
    >
      <p className=" text-center text-3xl font-bold mb-2  text-orange-900 ">
       {t("Common.OurService")}
      </p>
      <p className="text-center text-xl text-orange-950 mb-8 mx-9">
       {t("Home.OurService.titleDescription")}
      </p>
      <motion.div  variants={variants} className=" lg:w-4/5 py-2 flex justify-center items-center flex-col lg:flex-row m-auto">
        <ServiceList
          icon={DirectionsBoatOutlinedIcon}
          title={t("Common.Import")}
          description={t("Home.OurService.innerSection1.description")}
          href="/service-import"
        />
        <ServiceList
          icon={FlightIcon}
          title=  {t("Common.Export")}
          description={t("Home.OurService.innerSection2.description")}
          href="/service-export"
        />
        <ServiceList
          icon={GiMining}
          title=  {t("Common.LocalMarket")}
          description={t("Home.OurService.innerSection3.description")}
          href="/service-mining"
        />
      </motion.div>
    </motion.div>
  );
};

export default OurServices;
