import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ExportImage from "../../../../assets/Service/Export/Export";
import ItemDisplay from "../ItemDisplay/ItemDisplay";
import Banner from "../Banner/Banner";
import Quote from "../../Home/Quote/Quote";

const ExportPage = () => {
  const { t } = useTranslation(["Common"]);
  return (
    <div>
      <Banner
        title={t("Common.Export")}
        subTitle={t("Common.OurService")}
        link="/service"
      />
      <div className=" w-4/5 m-auto">
        <h2 className="text-2xl font-bold mb-6 text-orange-500">
          {t("Service.export.title")}
        </h2>
        {/* ---------------- */}
        <ItemDisplay
          style="md:flex-row"
          image={ExportImage.coper}
          title={t("Service.export.copper")}
          discription={t("Service.export.p")}
        />
        <ItemDisplay
          style="md:flex-row-reverse"
          image={ExportImage.mica}
          title={t("Service.export.mica")}
          discription={t("Service.export.p2")}
        />
        <ItemDisplay
          style="md:flex-row"
          image={ExportImage.lith1}
          title={t("Service.export.Lithium")}
          discription={t("Service.export.p3")}
        />
        <ItemDisplay
          style="md:flex-row-reverse"
          image={ExportImage.cop3}
          title={t("Service.export.chromite")}
          discription={t("Service.export.p4")}
        />
        <ItemDisplay
          style="md:flex-row"
          image={ExportImage.manganese}
          title={t("Service.export.manganese")}
          discription={t("Service.export.p5")}
        />
      </div>

      <Quote color=" -mb-5 bg-[#203934]" />
    </div>
  );
};

export default ExportPage;
