import React from "react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Why from "./Why";
import { useTranslation } from "react-i18next";

const WhyLists = () => {
  const { t } = useTranslation(["Common"]);
  return (
    <div className="">
      <p className=" text-start text-xl font-bold mb-2 ">
        {t("About.Why.title")}
      </p>
      <ul className="list-disc list-outside text-start text-lg text-black mb-8 mx-9">
        <li>{t("About.Why.note1")}</li>
        <li>
          {t("About.Why.note2")} <br />
          {t("About.Why.note3")}
        </li>
      </ul>
      <div className="py-2 grid lg:grid-cols-4  grid-cols-2 items-start text-left">
        <Why icon={HandshakeIcon} title={t("About.Why.caption1")} />
        <Why icon={LockOpenIcon} title={t("About.Why.caption2")} />
        <Why icon={ThumbUpOffAltIcon} title={t("About.Why.caption3")} />
        <Why icon={EnergySavingsLeafIcon} title={t("About.Why.caption4")} />
      </div>
    </div>
  );
};

export default WhyLists;
