import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Image from "../../../../assets/about/index"
import { motion } from "framer-motion";

const CompanyOverview = () => {
    const { t,i18n } = useTranslation(["Common"]);

    const variants = {
        initial: {
          x: 500,
          y: 100,
          opacity: 0,
        },
        animate: {
          x: 0,
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            staggerChildren: 0.1,
          },
        },
      };
    

    useEffect(() => {
        window.scrollTo(0, 300);
    
        const handleRouteChange = () => {
          window.scrollTo(0, 300);
        };
    
        window.addEventListener('popstate', handleRouteChange);
    
        return () => {
          window.removeEventListener('popstate', handleRouteChange);
        };
      }, []);

  return (
    <motion.div variants={variants} initial="initial" animate="animate">
      {" "}
      <div className="">
        <h1
          className={`pb-6  ${
            i18n.language === "ar" ? "text-right" : "text-left"
          } text-md md:text-xl xl:text-2xl font-bold text-orange-700 `}
        >
          {t("About.banner.title")}
        </h1>

        <div className="flex justify-center items-center ">
          <img src={Image.companyProfile} className=" h-[300px] w-full object-cover" />
        </div>
      </div>
      <div
        className={`mb-10 ${
          i18n.language === "ar" ? "text-right" : "text-left"
        } mt-6 `}
      >
        <p className="leading-6 indent-8">{t("About.banner.note.p1")}</p>
        <br />
        <p className="leading-6 indent-8">{t("About.banner.note.p2")}</p>
      </div>
    </motion.div>
  );
}

export default CompanyOverview
