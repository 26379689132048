import ecar from "./ecar.jpg";
import ecar2 from "./ecar2.jpg";
import eca3 from "./ecar3.jpg";
import furn1 from "./furn1.jpg";
import furn2 from "./furn2.jpg";
import furn3 from "./furn3.jpg";
import sparePart from "./spairparts.jpg";

const ImportImages = {
  ecar,
  ecar2,
  eca3,
  furn1,
  furn2,
  furn3,
  sparePart,
};

export default ImportImages
