import React from 'react'
import { useTranslation } from 'react-i18next';

const ItemDisplay = ({title, discription , image, style}) => {
    const { t,i18n } = useTranslation(["Common"]);
  return (
     <div className="max-w-6xl mx-auto p-8 bg-white rounded-lg shadow-sm mb-6">
    <div className={`flex flex-col  ${style} items-center`}>
       <div className="md:w-1/2 rounded-lg overflow-hidden">
         <img className="w-full h-auto" src={image} alt="coal" />
       </div>

       <div className="md:w-1/2 md:p-4">
         <div className="text-md text-brown leading-relaxed text-justify">
           <h2 className="text-3xl font-bold mb-6 text-orange-500">
            {title}
           </h2>
           <p className={`${i18n.language == "ar" ? "text-right" : "text-left"}`}>{discription}</p>
         </div>
       </div>
     </div>
   </div>

  )
}

export default ItemDisplay
