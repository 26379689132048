
import React from "react";
import logo from "../../assets/WhatsApp Image 2023-11-29 at 13.24.45_626125bd.png";
import { useTranslation } from "react-i18next";
import { CiFacebook } from "react-icons/ci";
import { RiTwitterXFill } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation(["Common"]);

  return (
    <div className="relative z-10 nav pb-10 pt-20 dark:bg-dark lg:pb-0 lg:pt-[20px] mt-4  ">
      <footer className="w-4/5 m-auto">
        <div className="container lg:flex">
          <div className="-mx-4 my-8 lg:flex">
            <div className="w-full px-4 sm:w-2/3 lg:w-3/12 ">
              <div className="mb-10 w-full">
                <Link to="/" className="mb-6 inline-block max-w-[160px]">
                  {/* <img
                    src="../../../public/assets/logo.jpg"
                    alt="logo"
                    className="max-w-full dark:hidden"
                  /> */}
                  <img
                    src={logo}
                    alt="logo"
                    // className=" dark:block"
                  />
                </Link>
                <p className="mb-7 text-base text-body-color text-slate-300">
                  Addis Ababa, Ethiopia
                </p>
                <div className="flex items-center text-sm font-medium text-slate-300 dark:text-white h-10">
                  <BsTelephone className="w-6 h-6 mr-4" />
                  <p>+251911248620</p>
                </div>
              </div>
            </div>

            <div className=" w-full grid grid-cols-2 lg:grid-cols-4">
              <LinkGroup header={t("Common.importServices")}>
                <NavLink
                  link="/service-import"
                  label={t("Service.import.car")}
                />
                <NavLink
                  link="/service-import"
                  label={t("Service.import.furniture")}
                />
                <NavLink
                  link="/service-import"
                  label={t("Service.import.spairParts")}
                />
                {/* <NavLink link="/#" label="User Strategy" /> */}
              </LinkGroup>
              <LinkGroup header={t("Common.exportServices")}>
                <NavLink
                  link="/service-export"
                  label={t("Service.export.copper")}
                />
                <NavLink
                  link="/service-export"
                  label={t("Service.export.Lithium")}
                />
                <NavLink
                  link="/service-export"
                  label={t("Service.export.mica")}
                />
                <NavLink
                  link="/service-export"
                  label={t("Service.export.chromite")}
                />
                <NavLink
                  link="/service-export"
                  label={t("Service.export.manganese")}
                />
              </LinkGroup>
              <LinkGroup header={t("Common.quickLinks")}>
                <NavLink link="/Service" label={t("Common.Service")} />
                <NavLink link="/About" label={t("Common.About")} />
                <NavLink link="/Contact" label={t("Common.Contact")} />
              </LinkGroup>

              <div className="w-full px-2">
                <div className="mb-10 w-full">
                  <h4 className="mb-3 text-lg font-semibold text-white dark:text-white underline underline-offset-8 decoration-orange-200 hover:text-[#FFA726] transition-all">
                    {t("Common.follow")}
                  </h4>
                  <div className="mb-6 flex items-center">
                    <CiFacebook className="mr-3 flex h-8 w-8 items-center justify-center text-dark hover:border-primary hover:bg-primary hover:text-orange-200 text-slate-300 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4" />

                    <RiTwitterXFill className="mr-3 flex h-6 w-8 items-center justify-center text-dark hover:border-primary hover:bg-primary hover:text-orange-200 text-slate-300 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4" />

                    <CiLinkedin className="mr-3 flex h-8 w-8 items-center justify-center text-dark hover:border-primary hover:bg-primary hover:text-orange-200 text-slate-300 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4" />

                    <FaWhatsapp className="mr-3 flex h-8 w-8 items-center justify-center text-dark hover:border-primary hover:bg-primary hover:text-orange-200 text-slate-300 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="absolute bottom-0 left-0 z-[-1]">
            <svg
              width={217}
              height={229}
              viewBox="0 0 217 229"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
                fill="url(#paint0_linear_1179_5)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1179_5"
                  x1="76.5"
                  y1={281}
                  x2="76.5"
                  y2="1.22829e-05"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.08" />
                  <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute right-10 top-10 z-[-1]">
            <svg
              width={75}
              height={75}
              viewBox="0 0 75 75"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
                fill="url(#paint0_linear_1179_4)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1179_4"
                  x1="-1.63917e-06"
                  y1="37.5"
                  x2={75}
                  y2="37.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#13C296" stopOpacity="0.31" />
                  <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </div>
        <div class=" flex justify-between border-t text-center border-[#6b5f5f] pt-2 mt-7">
          <p class="text-gray-300 text-[13px] font-semibold">
            Copyright © 2024{" "}
            <span className="hover:underline mx-1 text-[#FFA726] hover:text-[#FFA726]">
              Asfaw Tesfaye
            </span>
            All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};



const LinkGroup = ({ children, header }) => {
  return (
    <>
      <div className="w-full px-2">
        <div className="mb-10 w-full">
          <h4 className="mb-0 text-lg font-semibold text-white hover:text-[#FFA726] dark:text-white underline underline-offset-8 decoration-orange-200 transition-all">
            {header}
          </h4>
          <ul className="space-y-0 text-slate-300 ">{children}</ul>
        </div>
      </div>
    </>
  );
};

const NavLink = ({ link, label }) => {
  return (
    <li>
    
      <Link
        to={link}
        className="inline-block text-base leading-loose text-body-color hover:text-primary dark:text-dark-6 hover:text-[#FFA726] transition-all"
      >
        {label}
      </Link>
    </li>
  );
};

export default Footer;
