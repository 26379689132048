import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import Shared from './Components/Shared/Shared';
import HomePage from './Components/Pages/Home/HomePage';
import About from './Components/Pages/About/About';
import Service from './Components/Pages/Service/Service';
import Gallary from './Components/Pages/Gallary/Gallary';
import Contact from './Components/Pages/Contact/Contact';
import WhatsAppChatButton from './Components/Whatsapp';

import Layout from './Layout';
import ImportPage from './Components/Pages/Service/ImportPage/ImportPage';
import ExportPage from './Components/Pages/Service/ExportPage/ExportPage'
import MiningPage from './Components/Pages/Service/MiningPage/MiningPage';
import ScrollToTop from './ScrolToTop';



function App() {
  return (
    <Layout>
      <WhatsAppChatButton />
      <Suspense fallback={null}>
        <BrowserRouter >
        <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Shared />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/service" element={<Service />} />
              <Route path="/gallery" element={<Gallary />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/service-import" element={<ImportPage />} />
              <Route path="/service-export" element={<ExportPage/>}/>
              <Route path="/service-mining" element={<MiningPage/>}/>

            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Layout>
  );
}

export default App;