import React from 'react'
import { useTranslation } from 'react-i18next';
import middleHero from '../../../../assets/middleHero.jpeg'

function HeroSection() {
    const { t } = useTranslation(["Common"]);
  return (
    <div> 
     <div class="relative bg-black h-[360px] opacity-95 py-32 font-[sans-serif]">
      <div class="absolute inset-0">
        <img src={middleHero} alt="Background" class="w-full h-full object-cover opacity-50" />
      </div>
      <div class="relative max-w-screen-xl mx-auto px-8 z-10 text-center text-white">
        <h1 class="text-2xl opacity-50 -mt-5 md:text-3xl lg:text-4xl font-semibold leading-tight mb-6">{t("Home.Hero.p1")}</h1>
        <p class="text-lg md:text-xl mb-8 text-yellow-400">{t("Home.Hero.p2")}</p>
        {/* <button type="button" className=" hover:scale-105 justify-start bg-gradient-to-r from-orange-800 to-orange-600  hover:bg-indigo-800 text-white text-base font-semibold px-8 py-2.5 rounded-full transition duration-300 w-48 text-center ease-in-out shadow-lg hover:shadow-xl">{t("Common.ContactUs")}</button> */}
      </div>
    </div>
    </div>
  )
}

export default HeroSection