import one from "./anton-maksimov-5642-su-hs-x-bJa720-unsplash.jpg";
import two from "./chad-kirchoff-xe-e69j6-Ds-unsplash.jpg";
import three from "./chuttersnap-xJLsHl0hIik-unsplash.jpg";
import four from "./michael-warf-f8egRYt5RGk-unsplash.jpg";
import five from "./paul-alain-hunt-na_-KsSDFyQ-unsplash.jpg";
import six from "./shane-mclendon-EN1tF2EG-50-unsplash.jpg";

const GalleryImage = {
  one,
  two,
  three,
  four,
};

export default GalleryImage;
