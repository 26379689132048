import React from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Card({image,title,description,href}) {
  const { t,i18n } = useTranslation(["Common"]);
  return (
    <div className="md:me-4 shadow-md my-5 lg:my-1">
      <div className=" mb-6 border-gray-100 pb-6 relative">
        <img className=" w-full " src={image} alt="" />
        <h2 className="pb-2 pt-6 text-2xl font-bold text-center  text-orange-950">
         {title}
        </h2>
        <div className={`ocean ${i18n.language === "ar" ? "text-right" : "text-left"} `}>
          <p className=" flex justify-around m-auto px-5 md:px-3 lg:px-2">{description}</p>

          {/* */}
          <Link
            to={href}
            className="text-red-800 flex items-center justify-center mt-5 group mx-3"
          >
            <span className="relative font-bold text-lg items-center hover:text-red-600">
              {t("Common.ReadMore")}
              <ChevronRightOutlinedIcon className="absolute flex transform translate-x-0 group-hover:translate-x-3 duration-200 items-center mt-1" />
            </span>
          </Link>
        </div>
        <div className="absolute bottom-4 left-4"></div>
      </div>
    </div>
  );
}

export default Card;
