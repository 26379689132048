import React from "react";



const Why = ({ icon, title }) => {
  const IconComponent = icon;
  return (
    <div className="flex items-center justify-center">
      <div className="text-center w-3/5  items-center align-middle justify-between py-2">
        <div className="flex justify-center text-white font-bold text-6xl bg-gradient-to-r from-orange-800 to-orange-600 w-20 h-20 items-center m-auto rounded-full hover:rotate-45 duration-200">
          <IconComponent style={{ fontsize: "3rem" }} />
        </div>
        <p className="w-full mt-2 font-semibold text-black">{title}</p>
      </div>
    </div>
  );
};

export default Why;
