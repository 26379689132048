import coal from "./coal.jpg";
import coal2 from "./coal2.jpg";
import img1 from './coalStone.jpg'
import img2 from './coalStone2.jpg'

const MiningImage = {
  coal,
  coal2,
  img1,
  img2
};

export default MiningImage
