import React from "react";
import Banner from "./Banner/Banner";
import OurServices from "./OurService/OurServices";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
// import Testimonial from "./Testimonial/Testimonial";
import Quote from "./Quote/Quote";
// import Client from "./Client/Client";
// import OurServicesProcess from "./OurServicesProcess/OurServicesProcess";
import Gallery from "./Gallery/Gallery";
import HeroSection from "./MiddelHero/HeroSection";
import Vision from "./Vision/Vision";

const HomePage = () => {
  return (
    <div>
      <Banner />
      <OurServices />
      <HeroSection />
      <WhyChooseUs />
      <Quote color="nav" />
      {/* <OurServicesProcess /> */}
      {/* <Testimonial /> */}
      <div className=" flex flex-col lg:flex-row w-3/4 mt-6 m-auto gap-6">
        <Gallery />
        <Vision />
      </div>

      {/* <Client /> */}
    </div>
  );
};

export default HomePage;
