import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UsFlag from "../../../../assets/Common/usa-flag-icon-free-vector.jpg";
import ChinaFlag from "../../../../assets/Common/China-Official-Government-Flags_190_F.jpg";
import SaudiArabiaFlag from "../../../../assets/Common/Flag_of_the_United_Arab_Emirates.svg (1).png";

const LangSelector = () => {
  const { i18n, t } = useTranslation(["Common"]);
  const [isOpen, setIsOpen] = useState(false);

  const handellang = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative">
      <button
        className="flex items-center bg-white border border-orange-100 py-2 px-4 rounded-md text-sm leading-5 focus:outline-none focus:shadow-outline-blue focus:border-orange-600 transition duration-150 ease-in-out"
        onClick={toggleDropdown}
      >
        {localStorage.getItem("i18nextLng") === "en" && (
          <img src={UsFlag} alt="United States Flag" className="w-5 h-5 mr-2" />
        )}
        {localStorage.getItem("i18nextLng") === "ch" && (
          <img src={ChinaFlag} alt="China Flag" className="w-5 h-5 mr-2" />
        )}
        {localStorage.getItem("i18nextLng") === "ar" && (
          <img src={SaudiArabiaFlag} alt="Saudi Arabia Flag" className="w-5 h-5 mr-2" />
        )}
        <span>{localStorage.getItem("i18nextLng")}</span>
      </button>

      {isOpen && (
        <div className="absolute mt-2 bg-white border border-orange-100 rounded-md shadow-md">
          <button
            onClick={() => handellang("en")}
            className="flex items-center w-full p-2 hover:bg-gray-100"
          >
            <img src={UsFlag} alt="United States Flag" className="w-7 h-5 mr-2" />

          </button>
          <button
            onClick={() => handellang("ch")}
            className="flex items-center w-full p-2 hover:bg-gray-100"
          >
            <img src={ChinaFlag} alt="China Flag" className="w-7 h-5 mr-2" />

          </button>
          <button
            onClick={() => handellang("ar")}
            className="flex items-center w-full p-2 hover:bg-gray-100"
          >
            <img src={SaudiArabiaFlag} alt="Saudi Arabia Flag" className="w-7 h-5 mr-2" />

          </button>
        </div>
      )}
    </div>
  );
};

export default LangSelector;
