import React from "react";

const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.4805146843787!2d38.797972773528436!3d9.019854689135117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85deed815b5d%3A0x191b623c1f722160!2zQmV0ZWxoZW0gUGxhemEgfCBNZWdlbmFnbmEgfCDhiaThibDhiI3hiIThiJ0g4Y2V4YiL4YubIHwg4YiY4YyI4YqT4Yqb!5e0!3m2!1sen!2sus!4v1709332928142!5m2!1sen!2sus"
        className=" w-full h-96"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
