import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import img1 from "../../../assets/Gallary/Images/c1.jpg";
import img2 from "../../../assets/Gallary/Images/c2.jpg";
import img3 from "../../../assets/Gallary/Images/c4.jpg";
// import img4 from "../../../assets/Gallary/Images/c3.png";
import img5 from "../../../assets/Gallary/Images/s1.jpg";
import img6 from "../../../assets/Gallary/Images/s4.jpg";
import img7 from "../../../assets/Gallary/Images/s5.jpeg";
import img8 from "../../../assets/Gallary/Images/t1.jpg";
import img9 from "../../../assets/Gallary/Images/t3.jpg";
// import img10 from "../../../assets/Gallary/Images/t5.jpg";
import img11 from "../../../assets/Gallary/Images/t6.jpg";
// import img12 from "../../../assets/Gallary/Images/s7.jpg";

const Gallary = () => {
  const { t } = useTranslation(["Common"]);
  let data = [
    {
      id: 1,
      imgSrc: img1,
      category: "air",
      title: t("Service.import.car"),
    },
    {
      id: 2,
      imgSrc: img2,
      category: "air",
      title: t("Service.import.furniture"),
    },
    {
      id: 3,
      imgSrc: img3,
      category: "air",
      title: t("Service.import.spairParts"),
    },
    // {
    //   id: 4,
    //   imgSrc: img4,
    //   category: "air",
    //   title: "Road",
    // },
    {
      id: 5,
      imgSrc: img5,
      category: "ocean",
      title: t("Service.export.copper"),
    },
    {
      id: 6,
      imgSrc: img6,
      category: "ocean",
      title: t("Service.export.Lithium"),
    },
    {
      id: 7,
      imgSrc: img7,
      category: "ocean",
      title: t("Service.export.mica"),
    },

    {
      id: 8,
      imgSrc: img8,
      category: "road",
      title: t("Service.local.coal"),
    },
    {
      id: 9,
      imgSrc: img9,
      category: "road",
      title: t("Service.local.coal"),
    },
    // {
    //   id: 10,
    //   imgSrc: img10,
    //   category: "road",
    //   title: "Road",
    // },
    {
      id: 11,
      imgSrc: img11,
      category: "road",
      title: t("Service.local.coal"),
    },

    // {
    //   id: 12,
    //   imgSrc: img12,
    //   category: "ocean",
    //   title: "Ocean",
    // },
  ];

  
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentIndex, setCurrentIndex] = useState(0);

  const getImg = (imgSrc, category) => {
    setTempImgSrc(imgSrc);
    setModel(true);
    setSelectedCategory(category);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : data.length - 1
    );
    setTempImgSrc(data[currentIndex].imgSrc);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < data.length - 1 ? prevIndex + 1 : 0
    );
    setTempImgSrc(data[currentIndex].imgSrc);
  };

  const filterDataByCategory = (category) => {
    if (category === "all") {
      return data;
    }
    return data.filter((item) => item.category === category);
  };

  const variants = {
    initial: {
      x: -500,
      y: 100,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      className="bg-white  "
    >
      {/* -----Banner Part */}
      <div className="contact-banner  "></div>
      <div className=" -z-30 ps-12 lg:ps-0  -mt-80 mb-4 h-80 bg-black opacity-80 pt-36 lg:pt-10 lg:items-center flex flex-col lg:flex-row  lg:justify-around">
        <div className="   ">
          <p className="font-bold text-2xl text-white  ">
            {t("Common.gallery1")}
          </p>
          <p className="">
            <Link to="/" className=" text-orange-500 text-lg font-bold z-40">
              {t("About.banner1.menu")}
            </Link>
            <span className=" text-white"> / {t("Common.Gallery")}</span>
          </p>
        </div>
        <div className="flex flex-row lg:flex-col text-sm mt-4 lg:text-base">
          <p className=" text-white">{t("About.banner1.disc")}</p>
          <span className="text-white flex justify-end">
            {t("About.banner1.span")}
            <p className=" mx-1 text-orange-500 z-40 font-bold">
              {" "}
              {t("About.banner1.p")}
            </p>{" "}
            <span className="text-white">{t("About.banner1.p")}</span>{" "}
            <p className=" mx-1 text-orange-500 font-bold">
              {t("About.banner1.p2")}
            </p>
          </span>
        </div>
      </div>
      {/* -----Banner End---- */}

      <div className="ml-5 bg-white">
        {/* Gallery section with filter buttons */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="col-span-12 lg:flex justify-center w-full">
            <div id="" className="flex space-x-10">
              <button
                className={`bg-gray-300 px-2 items-center text-sm rounded-md cursor-pointer flex ${
                  selectedCategory === "all" ? "bg-orange-500" : ""
                }`}
                onClick={() => setSelectedCategory("all")}
              >
                <FiMenu className="pt-1" size="1.2em" />
                {t("Gallery.All")}
              </button>
              <button
                className={`bg-gray-300 p-2 cursor-pointer px-2  flex items-center rounded-md text-xs lg:text-sm ${
                  selectedCategory === "air" ? "bg-orange-500" : ""
                }`}
                onClick={() => setSelectedCategory("air")}
              >
                {t("Common.Import")}
              </button>
              <button
                className={`bg-gray-300 p-2 cursor-pointer px-2  flex items-center rounded-md text-xs lg:text-sm ${
                  selectedCategory === "ocean" ? "bg-orange-500" : ""
                }`}
                onClick={() => setSelectedCategory("ocean")}
              >
                {t("Common.Export")}
              </button>
              <button
                className={`bg-gray-300 p-2 cursor-pointer px-2  flex items-center rounded-md text-xs lg:text-sm ${
                  selectedCategory === "road" ? "bg-orange-500" : ""
                }`}
                onClick={() => setSelectedCategory("road")}
              >
                {t("Common.LocalMarket")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={model ? "model open  opacity-100 scale-100" : "model"}>
        {/* Previous button */}
        {/* Previous button */}
        <div
          className={`flex items-center ml-10 ${
            currentIndex === 0 ? "opacity-50" : ""
          }`}
        >
          <button
            className={`bg-gray-200 p-2 hover:bg-orange-500 ${
              currentIndex === 0 ? "cursor-not-allowed" : ""
            }`}
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
          >
            {t("Gallery.Previous")}
          </button>
        </div>

        <img src={tempimgSrc} alt="" />
        <CloseIcon
          className="fixed top-10 right-10 w-20 h-20 p-1 bg-opacity-40 bg-black text-white cursor-pointer"
          onClick={() => setModel(false)}
        />

        {/* Next Button */}
        <div className=" ">
          <div
            className={`flex items-center  mr-10${
              currentIndex === data.length - 1 ? "opacity-50" : ""
            }`}
          >
            <button
              className={`bg-gray-200 p-2 hover:bg-orange-500  ${
                currentIndex === data.length - 1 ? "cursor-not-allowed" : ""
              }`}
              onClick={handleNextClick}
              disabled={currentIndex === data.length - 1}
            >
              {t("Gallery.Next")}
            </button>
          </div>
        </div>
      </div>

      <div className="  md:grid md:grid-cols-3 md:gap-8 relative mr-20 ml-20 mb-20 mt-8">
        {filterDataByCategory(selectedCategory).map((item, index) => {
          return (
            <div
              className="pics md:col-span-1 relative overflow-hidden group"
              key={index}
              onClick={() => getImg(item.imgSrc, item.category)}
            >
              <div
                className="pics md:col-span-1 relative overflow-hidden cursor-pointer transition-all mb-6 group"
                key={index}
                onClick={() => getImg(item.imgSrc, item.category)}
              >
                <img
                  src={item.imgSrc}
                  alt="gallery"
                  className=" w-full transition-transform transform hover:-translate-y-12"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-orange-800  text-white p-4 opacity-100 lg:opacity-0 lg:transition-opacity lg:group-hover:opacity-100">
                  <p>{item.title}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default Gallary;
