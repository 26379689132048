import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Quote = ({ color }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`text-center flex flex-col md:flex-row justify-between my-9 ${color} p-4 md:p-8 lg:px-28`}
    >
      <p className=" text-sm md:text-lg lg:text-xl  p-4 text-white">
        {t("Home.Quote.Text")}
      </p>
      <a
        href="profile.pdf"
        download="CompanyProfile.pdf"
        className="hover:scale-105 text-center  mx-auto lg:mx-0 bg-gradient-to-r from-orange-800 to-orange-600 w-48 text-lg px-3 font-medium py-2 my-3 rounded-md text-white"
      >
        {t("Common.profile")}
      </a>
    </div>
  );
};

export default Quote;
