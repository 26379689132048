import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressOne from "./Address/AddressOne";
import image from "../../../assets/Contact/contact Large.jpeg";
import Map from "./Map";
import Banner from "../Service/Banner/Banner";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { t } = useTranslation(["Common"]);
  const [showMessageSent, setShowMessageSent] = useState(false);

  // handling contact submission form
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    //Logic to send email
    emailjs
      .sendForm("service_46zjwp3", "template_1zg8kfq", form.current, {
        publicKey: "gyN2uZUByEvyugS3w",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    //once it is sent seccessfull
    setShowMessageSent(true);
    //reset form
    e.target.reset();
    //hide message after 3 seconds
    setTimeout(() => setShowMessageSent(false), 3000);
  };
  return (
    <div className="   ">
      {/* -----Banner Part */}
      <Banner title={t("Common.ContactUs")} subTitle={t("Common.Contact")} />
      {/* -----Banner End---- */}

      <div className=" lg:w-4/5 lg:flex lg:flex-row-reverse m-auto ">
        {/* ----------Addres area-----  */}
        <div className=" lg:w-2/5 lg:ms-4 lg:mt-10">
          <AddressOne
            title="HEADQUARTERS"
            address="Addis Ababa, Ethiopia"
            fax="+251911248620"
            telephone="+251911248620"
            email="asfawtesfayeimportexport@gmail.com"
          />

          {/* <AddressOne
            title="CORPORATE OFFICE"
            address="5543, Bole ,Addis Ababa"
            fax="+2519453663"
            email="test@gmail.com"
          /> */}
        </div>
        {/* -------------Banner two and contact form start------- */}
        <div className=" warrper w-4/5 mx-auto mt-8 lg:w-2/3 ">
          {/* ------banner--- */}
          <div className="bannerTwo ">
            <span className="text-2xl flex mx-2 items-center pt-1">
              {t("Contact.banner.quote")}{" "}
              <p className=" text-orange-800 mx-2 text-3xl">:)</p>
            </span>
            <img src={image} className=" h-48 lg:h-44 w-full mt-2 mb-2" />
          </div>
          {/* ----contact---- */}
          <div className=" contactForm mb-8">
            <p className=" flex">
              <p className=" border-b-2 border-orange-700">
                {t("Common.form")}
              </p>
            </p>
            <form
              ref={form}
              onSubmit={sendEmail}
              className=" relative shadow-md p-3 mt-3 h-90"
            >
              <div className="w-full flex flex-col lg:flex-row  justify-between  ">
                <input
                  type="text"
                  placeholder={t("Contact.form.placeholder.name")}
                  name="user_name"
                  className="outline-none focus:border focus:border-orange-700 ps-2 border w-full py-1 my-2"
                  required
                />
                <input
                  type="text"
                  placeholder={t("Contact.form.placeholder.company")}
                  name="subject"
                  className="outline-none focus:border focus:border-orange-700 ps-2 border my-2 w-full  py-1  lg:ms-2"
                  required
                />
              </div>
              <div className="w-full flex flex-col lg:flex-row  justify-between  ">
                <input
                  type="text"
                  placeholder={t("Contact.form.placeholder.email")}
                  name="user_email"
                  className="outline-none focus:border focus:border-orange-700 ps-2 border w-full py-1 my-2"
                  required
                />
                <input
                  type="text"
                  placeholder={t("Contact.form.placeholder.number")}
                  className="outline-none focus:border focus:border-orange-700 ps-2 border my-2 w-full  py-1 lg:ms-2"
                />
              </div>

              <textarea
                placeholder={t("Contact.form.placeholder.msg")}
                name="message"
                className=" ps-2 border outline-none focus:border focus:border-orange-700 w-full h-44 resize-none relative"
                required
              />
              {showMessageSent && (
                <p className=" text-green-700">Message Sent Successfully!</p>
              )}
              <button
                type="submit"
                className="  bg-orange-700 px-8 rounded-full text-white text-lg mt-2 py-2"
              >
                {t("Contact.form.button")}
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* ----map-- */}
      <Map />
    </div>
  );
};

export default Contact;
