import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Vision = () => {
    const { t } = useTranslation(["Common"]);
  const variants = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 2,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      variants={variants}
      initial="initial"
      whileInView="animate"
      className=" w-full m-auto lg:w-1/2  flex flex-col flex-grow gap-4 h-full justify-between items-center"
    >
      <div  className=" w-96 hover:translate-x-10 duration-700  bg-slate-100 shadow-sm  py-6 px-6 text-xl font-bold text-orange-800 hover:text-[#d8a303]  hover:border-l-2 border-orange-600  xl:text-2xl ">
      {t("Common.WEBRIDGETHEGAp")}
      </div>
      <div className=" w-96 group hover:translate-x-10 duration-700  bg-slate-200 shadow-sm border-orange-600 hover:border-l-2 flex flex-col justify-between py-4 ps-6 pe-3 gap-3 ">
        <p className=" group-hover:text-[#d8a303] text-lg  xl:text-xl font-bold text-orange-800">
          {t("Home.vission.vission")}
        </p>
        <p className="  text-gray-400  group-hover:text-gray-500 text-sm  xl:text-md">
        {t("Home.vission.first")}
        </p>
      </div>
      <div className="w-96 group hover:translate-x-10 duration-700  bg-slate-200 shadow-sm border-orange-600 hover:border-l-2 flex flex-col justify-between py-4 ps-6 pe-3 gap-3 ">
        <p className=" group-hover:text-[#d8a303]   text-lg font-bold text-orange-800">  {t("Home.vission.mission")}</p>
        <p className=" text-gray-400 group-hover:text-gray-500 text-sm  xl:text-md">
        {t("Home.vission.second")}
        </p>
      </div>
    </motion.div>
  );
};

export default Vision;
