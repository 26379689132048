import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";


const ServiceList = ({ icon, title, description, href }) => {
  const { t,i18n } = useTranslation(["Common"]);
  const IconComponent = icon;

  return (
    <div className=" w-3/5 flex justify-center items-center my-5 mx-3 ">
      <div className="text-center items-center align-middle justify-between p-2">
        <div className="flex justify-center text-white font-bold text-6xl bg-gradient-to-r from-orange-700 to-orange-400 w-28 h-28 items-center m-auto rounded-full hover:rotate-45 duration-200 rota">
          <IconComponent style={{ fontSize: "3rem" }} />
        </div>
        <p className="m-2 text-xl font-bold text-red-950">{title}</p>
        <p className=" text-lg lg:text-base  ">{description}</p>
        <Link
          to={href}
          className="text-red-800 flex items-center justify-center mt-3 group mx-3"
        >
           <span
              className={`relative font-bold text-lg items-center hover:text-red-600`}
            >
              {i18n.language === "ar" && (
                <KeyboardArrowLeftIcon
                  className={`transform translate-x-0 group-hover:translate-x-[-9px] duration-200 flex`}
                />
              )}
              {t("Common.ReadMore")}
              {i18n.language !== "ar" && (
                <ChevronRightOutlinedIcon className="absolute flex transform translate-x-0 group-hover:translate-x-3 duration-200 items-center mt-1" />
              )}
            </span>
        </Link>
      </div>
    </div>
  );
};

export default ServiceList;