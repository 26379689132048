import i18n from "i18next"
import backend from "i18next-http-backend"
import LuangDetect from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n.use(backend).use(LuangDetect).use(initReactI18next).init({
    backend: {
        //translation file path
        loadPath: "assets/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "en",
    debug: false,
    ns:["Common" , "Home", "About","Service","Gallary","Contact"],
    interpolation:{
        espaceValue:false,
        formatSeparator:",",
    },
    react:{
        wait: true,
    },
}
);
export default i18n