import React from "react";
import Vision from "../../Home/Vision/Vision";
import Image from "../../../../assets/about/christina-wocintechchat-com-Q80LYxv_Tbs-unsplash.jpg";
import { useTranslation } from "react-i18next";
const Mission = () => {
  const {t} = useTranslation(["Common"])
  return (
    <div className="">
      <div>
        <img
          src={Image}
          className="h-[1000px] lg:h-[500px] w-full object-cover"
          alt="meeting"
        />
      </div>
      <div className=" h-[1000px] lg:h-[500px] w-full object-cover -mt-[1000px] lg:-mt-[500px] opacity-90 pb-20 pt-10 bg-white ">
        <div className=" w-4/5 m-auto flex flex-col justify-between gap-12 pb-12   lg:flex-row">
          <div className=" group md:w-[65%] m-auto lg:w-1/2 flex flex-col p12-7  justify-center px-0  ">
            <p className=" text-left text-xl text-gray-400 ml-0 group-hover:translate-x-32 duration-1000">
              {t("Common.MissionAndVission")}
            </p>
            <p className=" py-3 text-3xl group-hover:text-orange-700 duration-700">
              {t("About.Mission.tittle")}
            </p>
            <p className=" text-md text-gray-500 group-hover:text-gray-700 ">
              {t("About.Mission.firstText")}
            </p>
            <p className=" text-md text-gray-500 group-hover:text-gray-700 ">
              {t("About.Mission.SecondText")}
            </p>
          </div>
          <div className=" ">
            <Vision />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
