import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";
import logo from "../../../assets/WhatsApp Image 2023-11-29 at 13.24.45_626125bd.png";
import LangSelector from "./LangSelector/LangSelector";

const NavTwo = () => {
  const { i18n, t } = useTranslation(["Common"]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLinkClick = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const handellang = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <header className="relative">
      <div className="fixed top-0 left-0 right-0 z-50">
        <div className=" z-30 border-b bg-white w-full">
          <div className="px-6 md:px-12 lg:container lg:px-4 m-auto lg:py-2 w-full">
            <div className="flex items-center justify-between">
              <div className="cursor-pointer">
                <Link to="/">
                  <img
                    src={logo}
                    alt="AT logo"
                    className="h-16 w-30 py-2 lg:px-4"
                  />
                </Link>
              </div>

              {/* Mobile view sidebar */}
              <div className="flex items-center  lg:w-[calc(100%-20%)]  justify-end right-0 top-0 border-l-0 lg:border-none">
                <input
                  type="checkbox"
                  name="hamburger"
                  id="hamburger"
                  className="peer"
                  checked={sidebarOpen}
                  onChange={toggleSidebar}
                  hidden
                />
                {/* Toggle sidebar x and hanburger */}
                <label
                  htmlFor="hamburger"
                  className="peer-checked:hamburger block relative z-40 p-6 -mr-6 cursor-pointer lg:hidden"
                >
                  <div
                    aria-hidden="true"
                    className="m-auto h-0.5 w-6 rounded bg-sky-900 transform transition duration-300"
                  ></div>
                  <div
                    aria-hidden="true"
                    className="m-auto mt-2 h-0.5 w-6 rounded bg-sky-900 transform transition duration-300"
                  ></div>
                </label>
                {/* Side Bar */}
                <div
                  className={`peer-checked:translate-x-0 fixed inset-0 w-[calc(100%-50%)] lg:w-full -z-50 lg:z-40 translate-x-[${
                    sidebarOpen ? "0" : "-100%"
                  }] bg-white shadow-xl transition duration-300 lg:blrder-l-0 lg:static lg:shadow-none lg:translate-x-0`}
                >
                  <div className="flex flex-col h-full mt-4 lg:flex-row justify-between lg:justify-around lg:items-center">
                    <ul className=" flex-grow pt-32 text-gray-700 text-xl font-medium z-50  space-y-3 lg:space-y-0 lg:flex  lg:pt-0">
                      <li className="px-12 lg:px-0 border-t py-2 lg:border-none lg:py-0 w-full lg:text-right cursor-pointer">
                        <Link
                          to="/"
                          className="  cursor-pointer relative   hover:text-orange-700 hover:text-2xl "
                          onClick={handleLinkClick}
                        >
                          {t("Common.Home")}
                        </Link>
                      </li>
                      <li className="px-12 lg:px-0 border-t py-2 lg:border-none lg:py-0 lg:text-right w-full">
                        <Link
                          to="/about"
                          href="#"
                          className="hover:text-2xl  cursor-pointer relative   hover:text-orange-700   "
                          onClick={handleLinkClick}
                        >
                          {t("Common.About")}
                        </Link>
                      </li>
                      <li className="px-12 lg:px-0 border-t py-2 lg:border-none lg:text-right lg:py-0 w-full ">
                        <Link
                          to="/service"
                          href="#"
                          className="hover:text-2xl  cursor-pointer relative   hover:text-orange-700   "
                          onClick={handleLinkClick}
                        >
                          {t("Common.Service")}
                        </Link>
                      </li>
                      <li className="px-12  lg:px-0 border-t py-1 lg:text-right lg:border-none lg:py-0 w-full">
                        <Link
                          to="/gallery"
                          href="#"
                          className=" hover:text-2xl  cursor-pointer relative   hover:text-orange-700   "
                          onClick={handleLinkClick}
                        >
                          {t("Common.Gallery")}
                        </Link>
                      </li>
                      <li className=" px-12  lg:px-0 border-t lg:text-right py-1 lg:border-none lg:py-0 w-full">
                        <Link
                          to="/contact"
                          href="#"
                          className=" hover:text-2xl cursor-pointer relative   hover:text-orange-700   "
                          onClick={handleLinkClick}
                        >
                          {t("Common.Contact")}
                        </Link>
                      </li>
                      <li className=" lg:hidden px-12  lg:px-0 border-t lg:text-right border-b py-3   pb-4 lg:pb-0 lg:border-none  w-full">
                        <LangSelector />
                      </li>
                    </ul>

                    <div className=" z-50 flex flex-shrink-0 flex-row">
                      <div className="lg:w-50 border-t px-6 md:px-6 py-8 md:py-6 lg:border-none justify-end right-0 top-0 lg:py-0">
                        <p className=" block  text-center items-center px-6 py-3 rounded-lg bg-gradient-to-r from-orange-700 to-orange-400 text-white">
                          {t("Common.WEBRIDGETHEGAp")}
                        </p>
                      </div>
                      {/* ------------ */}
                      <div
                        className=" hidden lg:flex"
                        onClick={handleLinkClick}
                      >
                        <LangSelector />
                      </div>

                      {/* ----------- */}
                    </div>
                  </div>
                </div>
                {/* Side Bar End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavTwo;
