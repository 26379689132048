import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import image from "../../../../assets/Home/Banner/handshake.jpeg";

const variants = {
  initial: {
    y: 100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const WhyChooseUs = () => {
  const { t, i18n } = useTranslation(["Common"]);
//   console.log(i18n.language);
  return (
    <motion.div
      className="text-center align-middle my-4 py-4"
      variants={variants}
      initial="initial"
      whileInView="animate"
    >
      <motion.div
        variants={variants}
        className="w-4/5 m-auto py-8 flex flex-col lg:flex-row justify-around items-center space-x-8"
      >
        <motion.div className="">
          <motion.img src={image} className="w-80 h-80" alt="Banner" />
        </motion.div>
        <motion.div
          className={`lg:w-2/5 mt-4 lg:mt-0 ${
            i18n.language === "ar" ? "text-right" : "text-left"
          }  p-0 `}
        >
          <p className="text-xl font-medium border-b-2 border-orange-600 w-44 mb-2">
            {t("Common.WhyChooseUs")}
          </p>
          <p className="mb-2 text-md">{t("Home.WhyChooseUs.p1")}</p>
          <p className="text-base mb-2">{t("Home.WhyChooseUs.p2")}</p>
          <Link
            to="/about"
            className="text-red-800 flex items-center   group "
          >
            <span
              className={`relative font-bold text-lg items-center hover:text-red-600 `}
            >
              {i18n.language === "ar" && (
                <KeyboardArrowLeftIcon
                  className={`transform  translate-x-0 group-hover:translate-x-[-9px] duration-200 flex flex-row `}
                />
              )}
              {t("Common.ReadMore")}
              {i18n.language !== "ar" && (
                <ChevronRightOutlinedIcon className="absolute flex flex-row transform translate-x-0 group-hover:translate-x-3 duration-200 items-center mt-1" />
              )}
            </span>
          </Link>
        </motion.div>
        <div className=" border-l-2 h-80 border-orange-700 lg:w-[28%]  flex flex-col items-start mt-2 lg:mt-0 pt-6  space-y-1 justify-start left-0 lg:-ps-2">
          <span className=" flex space-x-4 items-center">
            <p className=" border-b-2 w-14 border-orange-700 -me-4 "></p>
            <HandshakeIcon
              className=" bg-gradient-to-r from-orange-700 to-orange-600 text-white m-2 p-2 rounded-full"
              style={{ width: 50, height: 50 }}
            />
            <p>{t("Home.WhyChooseUs.Trusted")}</p>
          </span>
          <span className=" flex space-x-4 items-center">
            <p className=" border-b-2 w-14 border-orange-700 -me-4 "></p>
            <LockOpenIcon
              className=" bg-gradient-to-r from-orange-700 to-orange-600 text-white my-2 py-2 rounded-full"
              style={{ width: 50, height: 50 }}
            />
            <p className=" ">{t("Home.WhyChooseUs.Security")}</p>
          </span>
          <span className=" flex space-x-4 items-center">
            <p className=" border-b-2 w-14 border-orange-700 -me-4 "></p>
            <ThumbUpOffAltIcon
              className=" bg-gradient-to-r from-orange-700 to-orange-600 text-white m-2 p-2 rounded-full"
              style={{ width: 50, height: 50 }}
            />
            <p>{t("Home.WhyChooseUs.Guarantee")}</p>
          </span>
          <span className=" flex space-x-4 items-center">
            <p className=" border-b-2 w-14 border-orange-700 -me-4 "></p>
            <LocationOnIcon
              className=" bg-gradient-to-r from-orange-700 to-orange-600 text-white m-2 p-2 rounded-full"
              style={{ width: 50, height: 50 }}
            />
            <p>{t("Home.WhyChooseUs.Location")}</p>
          </span>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default WhyChooseUs;

//
