import coper from "./cooper.jpg";
import cop1 from "./cop1.jpg";
import cop2 from "./cop2.jpg";
import cop3 from "./cop3.jpg";
import lith1 from "./lith1.jpg";
import manganese from "./manganese.jpg";
import mica from "./mica.jpg";

const ExportImage = {
  coper,
  cop1,
  cop2,
  cop3,
  lith1,
  manganese,
  mica,
};

export default ExportImage;
