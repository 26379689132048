import React from 'react'
import { Outlet } from "react-router-dom";
import Footer from './Footer';
import Header from './Header';
import NavOne from './NavBar/NavOne';
import NavTwo from './NavBar/NavTwo';

const Shared = () => {
  return (
    <div>
    {/* <Header  /> */}
    <NavOne/>
    <NavTwo/>
    <Outlet />
    <Footer/>
  </div>
  )
}

export default Shared
