import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ItemDisplay from "../ItemDisplay/ItemDisplay";
import Banner from "../Banner/Banner";
import Quote from "../../Home/Quote/Quote";
import MiningImage from "../../../../assets/Service/Mining/MiningImage";



const MiningPage = () => {
  const { t } = useTranslation(["Common"]);


  return (
    <div>
      <Banner
        title={t("Common.LocalMarket")}
        link="/service"
        subTitle={t("Common.OurService")}
      />

      <div className=" w-4/5 m-auto ">
        <div className="text-2xl font-bold mb-6 text-orange-500">
          <h1>{t("Common.LocalMarket")}</h1>
        </div>
        <ItemDisplay
          style="md:flex-row"
          image={MiningImage.img2}
          title={t("Service.local.coal")}
          discription={t("Service.local.p")}
        />
      </div>

      <Quote color=" -mb-5 bg-[#203934]" />
    </div>
  );
};

export default MiningPage;
