import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GalleryImage from "../../../../assets/Home/Gallery/Image";
import { motion } from "framer-motion";

const Gallery = () => {
  const galleryItems = Object.entries(GalleryImage);
  const { t } = useTranslation(["Common"]);

  const variants = {
    initial: {
      y: 50,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 2,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      whileInView="animate"
      className="w-full lg:w-3/5"
    >
      {/* <p className=" text-xl font-medium">{t("Common.RecentGallery")}</p> */}
      <motion.div variants={variants} className={` grid grid-cols-2 gap-4`}>
        {galleryItems.map(([key, value], id) => (
          <div
            key={id}
            className="overflow-hidden gap-2  transform transition-transform hover:scale-105"
          >
            <img
              className=" h-full w-full object-cover "
              src={value}
              alt={`Gallery Image ${id + 1}`}
            />
          </div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Gallery;
