import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import importe from "../../../assets/Service/images/importe.jpg";
import exporte from "../../../assets/Service/images/export2.jpg";
import mining from "../../../assets/Service/images/mining.jpg";
import Client from "../Home/Client/Client";
import Banner from "./Banner/Banner";
import Card from "./Card/Card";
import Quote from "../Home/Quote/Quote";

const Service = () => {
  const { t, i18n } = useTranslation(["Common"]);

  const variants = {
    initial: {
      x: -500,
      y: 100,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };

  return (

    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      className="align-middle"
    >
      <Banner
        title={t("Common.OurService")}
        subTitle={t("Common.Service")}
        link="/service"
      />


      {/* ------First section start---------*/}
      <motion.div className=" pt-5 w-4/5 m-auto">
        <div className="">
          <p className=" text-center m-auto text-2xl mb-3 font-bold text-orange-950">
            {t("Common.OurService")}
          </p>
        </div>
        <p
          className={`text-orange-950 mb-8 text-center`}
        >
          {t("Service.sectionOne.paragraph.p")}
        </p>
      </motion.div>
      {/* ------First section start end---------*/}

      <motion.div
        variants={variants}
        className=" flex flex-col md:flex-row w-4/5 m-auto"
      >
        <Card
          title={t("Common.Import")}
          image={importe}
          description={t("Service.sectionTwo.paragraph.p")}
          href="/service-import"
        />
        <Card
          title={t("Common.Export")}
          image={exporte}
          description={t("Service.sectionThree.paragraph.p")}
          href="/service-export"
        />
        <Card
          title={t("Common.LocalMarket")}
          image={mining}
          description={t("Service.sectionFour.paragraph.p")}
          href="/service-mining"
        />
      </motion.div>
      {/* <Client className="m-auto" /> */}

      <Quote color=" -mb-5 bg-[#203934]" />
    </motion.div>

  );
};

export default Service;
