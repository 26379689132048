import folder from "./folder.png";
import handshake from "./handshake.png";
import containers from "./image1.jpg";
import location from "./location.png";
import padlock from "./padlock.png";
import people from "./people.png";
import thumbsUp from "./thumbsUp.png";
import truck from "./truck.png";
import user from "./user.png";
import aboutUs from "./companyOverview.jpg";
import quality from "./quality.jpg";
import bannerImg from "../Contact/mateusz-suski-MnayUWBXurI-unsplash.jpg";
import strength from "./strength.jpg";
import vision from "./visions.jpg";
import overview from "./overview.jpg"
import Asfaw from './Asfaw.JPG'
import companyProfile from './Company-Profile.jpg'
import vision2 from './vision2.jpg'

const Image = {
  folder,
  handshake,
  containers,
  location,
  padlock,
  people,
  thumbsUp,
  truck,
  user,
  aboutUs,
  quality,
  bannerImg,
  strength,
  vision,
  overview,
  Asfaw,
  companyProfile,
  vision2,
};

export default Image;
