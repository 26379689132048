import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PeopleIcon from "@mui/icons-material/People";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { Link } from "react-router-dom";
import WhyLists from "./whyChooseUs/WhyLists";
import CompanyOverview from "./Lists/CompanyOverview";
import Asspiration from "./Lists/Asspiration";
import Strength from "./Lists/Strength";
import Quality from "./Lists/Quality";
import Banner from "../Service/Banner/Banner";
import MeetCeo from "./CEO/MeetCeo";
import Mission from "./Mission/Mission";
import Quote from "../Home/Quote/Quote";

const About = ({ icon }) => {
  const [discriptionDisplay, setDiscriptionDisplay] = useState(null);
  const { t } = useTranslation(["Common"]);

  const variants = {
    initial: {
      x: -200,
      y: 100,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };

  const renderDescriptionComponent = () => {
    if (discriptionDisplay === 2) {
      return <Asspiration />;
    } else if (discriptionDisplay === 3) {
      return <Strength />;
    } else if (discriptionDisplay === 4) {
      return <Quality />;
    } else {
      return <CompanyOverview />;
    }
  };
  return (
    <motion.div variants={variants} initial="initial" animate="animate">
      {/* =======Banner Section====== */}
      <div className="   ">
        <Banner
          title={t("Common.Aboutus")}
          subTitle={t("Common.About")}
          link="/about"
        />
      </div>
      {/* =======Banner Section End====== */}

      {/* ----------Description Area------------ */}
      <div className=" w-4/5 mt-12 flex flex-col justify-around lg:space-x-5  m-auto lg:flex-row-reverse">
        <div className=" flex flex-col justify-around lg:ms-4 ">
          {/* ----renderDescription------ */}
          {renderDescriptionComponent()}
          <div>
            <WhyLists />
          </div>
        </div>
        {/* ---side bottons----- */}
        <div className=" lg:w-2/6 sticky top-0">
          <div className="  sticky top-24">
            <div className="w-full items-center">
              <div className="text-sm  flex flex-col w-full">
                <button onClick={() => setDiscriptionDisplay(1)}>
                  <li className=" h-[42px] flex items-center justify-between bg-gray-200 hover:translate-x-2  duration-300 px-6 py-2 cursor-pointer mb-[4px] rounded-md">
                    {t("About.banner.title")}
                    <span>
                      <KeyboardArrowRightIcon />
                    </span>
                  </li>
                </button>

                <button onClick={() => setDiscriptionDisplay(2)}>
                  <li className=" h-[42px] flex items-center justify-between bg-gray-200 hover:translate-x-3  duration-300 px-6 py-2 cursor-pointer mb-[4px] rounded-md">
                    {t("About.banner2.title")}
                    <span>
                      <KeyboardArrowRightIcon />
                    </span>
                  </li>
                </button>

                <button onClick={() => setDiscriptionDisplay(3)}>
                  <li className=" h-[42px] flex items-center justify-between bg-gray-200 hover:translate-x-2  duration-300 px-6 py-2 cursor-pointer mb-[4px] rounded-md">
                    {t("About.banner3.title")}
                    <span>
                      <KeyboardArrowRightIcon />
                    </span>
                  </li>
                </button>

                <button onClick={() => setDiscriptionDisplay(4)}>
                  <li className=" h-[42px] flex items-center justify-between bg-gray-200 hover:translate-x-2  duration-300 px-6 py-2 cursor-pointer mb-[4px] rounded-md">
                    {t("About.banner4.title")}
                    <span>
                      <KeyboardArrowRightIcon />
                    </span>
                  </li>
                </button>
              </div>
            </div>
            <Link to="/contact">
              <button className=" w-full lg:w-[280px] h-[42px] font-semibold rounded-md text-center bg-gradient-to-r from-orange-800 to-orange-600 hover:from-orange-700 hover:to-orange-500 text-white py-2 px-6 cursor-pointer mt-10 mb-5">
                {t("About.Why.contact")}
              </button>
            </Link>
            <MeetCeo className="" />
          </div>
        </div>
        {/* ---bottons end----- */}
      </div>

      {/* ----------Description Area------------ */}
      <div>
        {/* =======Middle Banner Section====== */}

        <div className=" w-full h-full bg-cover bg-no-repeat my-8 sm:w-full sm:h-full">
          <div className="about-middle-banner h-[490px] -mb-[490px]  lg:mb-0 lg:h-[256px] "></div>
          <div className=" ps-12 lg:ps-0 -mt-64 mb-4 h-[490px] lg:h-64 bg-black opacity-80  ">
            <div className=" w-3/4 m-auto items-center flex flex-col py-12 lg:flex-row  lg:justify-around">
              <div className="text-white px-4 group   z-50 items-center hover:translate-y-10 duration-500">
                <span className="w-[86px] h-[74px] bg-gradient-to-r z-50 from-orange-500 to-orange-700 rounded-xl items-center px-8 py-6 ml-8 -mb-20 hover:translate-x-10 ">
                  <PeopleIcon className="h-130 w-30" />
                </span>
                <p className="w-[280px] h-[112px] z-30 bg-white rounded-xl opacity-90 text-4xl text-orange-500 text-start font-bold pt-10 pl-4">
                  BE{" "}
                  <span className="text-black text-base">
                    {t("About.BannerMid.div2")}
                  </span>
                </p>
              </div>
              <div className="text-white px-4   z-50 items-center  hover:translate-y-10 duration-500">
                <span className="w-[86px] h-[74px] bg-gradient-to-r from-orange-500 to-orange-700 rounded-xl items-center px-8 py-6 ml-8 -mb-20 z-50">
                  <ImportExportIcon className="h-130 w-30" />
                </span>
                <p className="w-[280px] h-[112px] z-30 bg-white rounded-xl opacity-90 text-4xl text-orange-500 text-start font-bold pt-10 px-4">
                  DO{" "}
                  <span className="text-black text-base">
                    {t("About.BannerMid.div3")}
                  </span>
                </p>
              </div>
              <div className="text-white px-4  z-50 items-center  hover:translate-y-10 duration-500">
                <span className="w-[86px] h-[74px] bg-gradient-to-r from-orange-500 to-orange-700 rounded-xl items-center px-8 py-6 ml-8 -mb-20 z-50">
                  <MoreTimeIcon />
                </span>
                <p className="w-[280px] h-[112px] z-30 bg-white rounded-xl opacity-90 text-4xl text-orange-500 text-start font-bold pt-10 px-4">
                  24/7{" "}
                  <span className="text-black text-base">
                    {t("About.BannerMid.div4")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <Mission />

        {/* =======Client Section====== */}

        <Quote color=" -mb-5 bg-[#203934]" />
        {/* =======Client Section End====== */}
      </div>
    </motion.div>
  );
};

export default About;
