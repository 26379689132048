import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ItemDisplay from "../ItemDisplay/ItemDisplay";
import Banner from "../Banner/Banner";
import Quote from "../../Home/Quote/Quote";
import ImportImages from "../../../../assets/Service/Import/ImportImage";

const ImportPage = () => {
  const { t } = useTranslation(["Common"]);

  return (
    <div>
      <Banner
        title={t("Common.Import")}
        link="/service"
        subTitle={t("Common.OurService")}
      />
      <div className=" w-4/5 m-auto ">
        <div className="text-2xl font-bold mb-6 text-orange-500">
          <h1>{t("Service.import.title")}</h1>
        </div>

        <ItemDisplay
          style="md:flex-row"
          image={ImportImages.ecar}
          title={t("Service.import.car")}
          discription={t("Service.import.p")}
        />
        <ItemDisplay
          style="md:flex-row-reverse"
          image={ImportImages.furn2}
          title={t("Service.import.furniture")}
          discription={t("Service.import.p2")}
        />
        <ItemDisplay
          style="md:flex-row"
          image={ImportImages.sparePart}
          title={t("Service.import.spairParts")}
          discription={t("Service.import.p3")}
        />

        {/* ---------------------------------------------------------------------------------- */}
      </div>

      <Quote color=" -mb-5 bg-[#203934]" />
    </div>
  );
};

export default ImportPage;
