import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { useTranslation } from 'react-i18next';
import { CiFacebook } from "react-icons/ci";
import { RiTwitterXFill } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";


const AddressOne = ({title,address,telephone,fax,email}) => {
  const {t} = useTranslation(["Common"])
  return (
    <div className=" w-4/5 lg:w-auto mx-auto mt-8 mb-4">
      <div className="title w-full bg-orange-800 text-white px-5 py-1 rounded-t-md text-lg">
        {title}
      </div>
      <div className="wrapper bg-slate-200 pb-5">
        <div className="address flex flex-row p-2 items-center">
          <LocationOnIcon className=" text-orange-800 me-2" />
          <div>
            <p className=" -mb-1">{t("Contact.address.add")}</p>
            <p>{address}</p>
          </div>
        </div>
        <div className="telephone flex flex-row p-2 items-center">
          <PhoneIcon className=" text-orange-800 me-2" />
          <div>
            <p className="-mb-1 ">{t("Contact.address.telephone")}</p>
            <p>{telephone}</p>
          </div>
        </div>
        <div className="fax flex flex-row p-2 items-center">
          <FaxIcon className=" text-orange-800 me-2" />
          <div>
            <p className=" -mb-1">{t("Contact.address.fax")}</p>
            <p>{fax}</p>
          </div>
        </div>
        <div className="email flex flex-row p-2 items-center">
          <MarkEmailReadIcon className=" text-orange-800 me-2" />
          <div>
            <p className=" -mb-1">{t("Contact.address.email")}</p>
            <p>{email}</p>
          </div>
        </div>

        <div className="w-full px-2">
          <div className="mb-10 mt-3 ml-1  w-full">
            <h4 className="mb-3 text-lg font-semibold text-black transition-all">
              {t("Common.follow")}
            </h4>
            <div className="mb-6 flex items-center">
              <CiFacebook className="mr-3 flex h-6 w-6 items-center justify-center text-orange-800 dark:text-white sm:mr-4 lg:mr-3 xl:mr-4" />

              <RiTwitterXFill className="mr-3 flex h-6 w-6 items-center justify-center text-orange-800 dark:text-white sm:mr-4 lg:mr-3 xl:mr-4" />

              <CiLinkedin className="mr-3 flex h-6 w-6 items-center justify-center text-orange-800 dark:text-white sm:mr-4 lg:mr-3 xl:mr-4" />

              <FaWhatsapp className="mr-3 flex h-6 w-6 items-center justify-center text-orange-800 dark:text-white sm:mr-4 lg:mr-3 xl:mr-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressOne
