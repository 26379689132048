import React from 'react'
import Image from '../../../../assets/about'
import { useTranslation } from 'react-i18next';

const MeetCeo = () => {
    const {t, i18n} = useTranslation(["Common"])
  return (
    <div className="py-10 px-4 shadow-md">
      <div>
      <div className="flex justify-center items-center h-50 pb-4 ">
          <img src={Image.Asfaw} className=" w-full" />
        </div>
        <p className='text-center font-semibold '>Asfaw Tesfaye</p>
        <p
          className={` font-semibold
          text-center text-md }`}
        >
         CEO
        </p>
        
      </div>
      <div>
        <p className="text-sm font-light text-gray-500 text-center mt-4">
          {t("About.Team.p")}
        </p>
      </div>
    </div>
  );
}

export default MeetCeo