import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Banner({title, subTitle, link}) {
  const { t } = useTranslation(["Common"]);
  return (
    <div className="   ">
      <div className="contact-banner"></div>
      <div className=" -z-30 ps-12 lg:ps-0  -mt-80 mb-4 h-80 bg-black opacity-80 pt-36 lg:pt-10 lg:items-center flex flex-col lg:flex-row  lg:justify-around">
        <div className="   ">
          <p className="font-bold text-2xl text-white  ">{title}</p>
          <p className="">
            <Link to="/">
              <span className=" text-orange-500 text-lg font-bold z-40">
                {t("Common.Home")}
              </span>
            </Link>
            <Link to={link}>
              <span className=" text-white"> / {subTitle}</span>
            </Link>
          </p>
        </div>
        <div className="flex flex-row lg:flex-col text-sm mt-4 lg:text-base">
          <p className=" text-white md:justify-start">
            {t("Common.BannerDiscription")}
            <span className="text-white flex justify-end">
              {t("Common.Company")}
              <p className=" mx-1 text-orange-500 z-40 font-bold">
                {t("Common.Fast")}
              </p>
              <span> {t("Common.And")}</span>{" "}
              <p className=" mx-1 text-orange-500 font-bold">
                {" "}
                {t("Common.Safely")}
              </p>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Banner